import {
  Card,
  Col,
  Progress,
  Radio,
  Row,
  Select,
  Skeleton,
  Tabs,
  Typography,
  Descriptions,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import AcquiredCustomer from "./_AcquiredCustomer";
import AreaCart from "./_AreaChart";
import DoughnutCart from "./_DoughnutCart";
import DriversGraph from "./_Drivers";
import LineChartWithoutAxis from "./_Line";
import LineChart from "./_LineCart";
import { useNavigate } from "react-router";
import { useAuthContext } from "../../context/AuthContext";
import { formatPhone } from "../../helper/functions";
import lang from "../../helper/langHelper";
import PostAndRedirectButton from "../../components/Payment";
const { TabPane } = Tabs;
const { Title, Text } = Typography;

const currentYear = new Date().getFullYear();
const years = Array.from({ length: currentYear - 2024 + 1 }, (_, i) => currentYear - i);

function Home() {
  const { setPageHeading, country } = useContext(AppStateContext);

  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(years[0]);
  const { userProfile, isAdmin } = useAuthContext();
  const [dashboard, setDashboard] = useState();

  const count = [
    {
      today: "Number of Users",
      title: `${dashboard && dashboard ? dashboard.totalCustomer : 0}`,
      persent: "10%",
      icon: <i class="fas fa-user-friends"></i>,
      bnb: "bnb2",
      url: "/customer",
      key: "totalCustomer",
      _7Day: dashboard && (dashboard.user7 ?? 0),
      _14Day: dashboard && (dashboard.user14 ?? 0),
    },
    {
      today: "Number of Active Users",
      title: `${
        dashboard && dashboard.activeCustomer ? dashboard.activeCustomer : 0
      }`,
      icon: <i class="fas fa-people-carry"></i>,
      bnb: "bnb2",
      url: `/customer?status=1&year=${year}`,
      key: "activeCustomer",
      _7Day: dashboard && (dashboard.activeUser7 ?? 0),
      _14Day: dashboard && (dashboard.activeUser14 ?? 0),
    },
    {
      today: "Number of Orders Delivered",
      title: `${dashboard && dashboard.totalOrder ? dashboard.totalOrder : 0}`,
      icon: <i class="fas fa-user-tie"></i>,
      bnb: "bnb2",
      url: "/order?status=delivered",
      key: "totalOrder",
      _7Day: dashboard && (dashboard.order7 ?? 0),
      _14Day: dashboard && (dashboard.order14 ?? 0),
    },
    {
      today: "No of Restaurant Registered",
      title: `${
        dashboard && dashboard.totalRestaurant ? dashboard.totalRestaurant : 0
      }`,
      icon: <i class="fas fa-copyright"></i>,
      bnb: "bnb2",
      url: "/restaurant",
      key: "totalRestaurant",
      _7Day: dashboard && (dashboard.restaurant7 ?? 0),
      _14Day: dashboard && (dashboard.restaurant14 ?? 0),
    },
    // {
    //   today: "Total Sales & Costs",
    //   title: `${dashboard && dashboard.totalSales ? dashboard.totalSales : 0}`,
    //   icon: <i class="fas fa-copyright"></i>,
    //   bnb: "bnb2",
    //   url: "/finance",
    //   key: "totalSales",
    //   _7Day: dashboard && (dashboard.restaurant7 ?? 0),
    //   _14Day: dashboard && (dashboard.restaurant14 ?? 0),
    // },
    {
      today: "Delivery Agents registered",
      title: `${
        dashboard && dashboard.totalDriver ? dashboard.totalDriver : 0
      }`,
      icon: <i class="fas fa-copyright"></i>,
      bnb: "bnb2",
      url: "/drivers",
      key: "totalDriver",
      _7Day: dashboard && (dashboard.driver7 ?? 0),
      _14Day: dashboard && (dashboard.driver14 ?? 0),
    },
  ];

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setYear(value);
  };

  useEffect(() => {
    setPageHeading(lang("Welcome Admin"));
    if (!userProfile) return;

    if (userProfile.type == "SubAdmin") {
      setPageHeading(`Welcome ${userProfile.name}`);
      if (!userProfile.permission.includes("dashboard-management")) return;
    }

    setLoading(true);
    request({
      url: apiPath.dashboard + `${year ? `?year=${year}` : ""}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setDashboard(data.data);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  }, [year, country.country_id, userProfile]);

  return (
    <>
      {userProfile &&
      !userProfile.permission.includes("dashboard-management") &&
      userProfile.type == "SubAdmin" ? (
        <Row>
          <Col xs={24} sm={24} md={24}>
            <Descriptions
              column={{ xs: 16, sm: 16, md: 24 }}
              title="Sub Admin Info"
            >
              <Descriptions.Item label="Name">
                {userProfile.name}
              </Descriptions.Item>
              <Descriptions.Item label="Role title">
                {userProfile.title}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile Number">
                {formatPhone(
                  userProfile.country_code,
                  userProfile.mobile_number,
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Cities">
                {userProfile.city_ids.map((item) => item.name).join(", ")}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      ) : (
        <div className="page-top-space home-card layout-content">
          <div className="mb-24">
            <div className="sub_title">
              <p>{lang("Here is the information about all your business")}</p>
              <div className="bussiness_year">
                <span>{lang("Year")}</span>
                <Select
                  value={year}
                  style={{ width: 120 }}
                  onChange={handleChange}
                  options={years.map((item) => ({ value: item, label: item }))}
                />
              </div>
            </div>
            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={24}>
                <Row
                  className="rowgap-vbox"
                  gutter={[24, 16]}
                  // style={{ marginLeft: "0" }}
                >
                  {count.map((c, index) => (
                    <Col
                      key={index}
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={8}
                      className="mb24"
                      // style={{ paddingRight: "0px" }}
                    >
                      {/* <Link to={c.url}> */}
                      <CountCard c={c} key={index} loading={loading} />
                      {/* </Link> */}
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </div> 
           {/* <PostAndRedirectButton/>  */}

          {/* <Row className="mt-3" gutter={[24, 0]}>
            <Col xs={24} xl={12} lg={24} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                <div className="graph-title">
                  <Title level={5}>{lang("Best Restaurant By Sales")}</Title>
                  <Row style={{ justifyContent: "end" }}>
                    <Radio.Group defaultValue="month" buttonStyle="solid">
                      <Radio.Button onClick={() => null} value="week">
                        {lang("Week")}
                      </Radio.Button>
                      <Radio.Button onClick={() => null} value="month">
                        {lang("Month")}
                      </Radio.Button>
                      <Radio.Button onClick={() => null} value="year">
                        {lang("Year")}
                      </Radio.Button>
                    </Radio.Group>
                  </Row>
                </div>
                {loading ? (
                  [1, 2, 3].map((item) => <Skeleton active key={item} />)
                ) : (
                  <>
                    {" "}
                    <LineChart borderColor="#1EB564" />{" "}
                  </>
                )}
              </Card>
            </Col>

            <Col xs={24} xl={12} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                <div className="graph-title">
                  <Title level={5}>{lang("Total Sales")}</Title>
                  <Row style={{ justifyContent: "end" }}>
                    <Radio.Group defaultValue="month" buttonStyle="solid">
                      <Radio.Button onClick={() => null} value="week">
                        {lang("Week")}
                      </Radio.Button>
                      <Radio.Button onClick={() => null} value="month">
                        {lang("Month")}
                      </Radio.Button>
                      <Radio.Button onClick={() => null} value="year">
                        {lang("Year")}
                      </Radio.Button>
                    </Radio.Group>
                  </Row>
                </div>
                {loading ? (
                  [1, 2, 3].map((item) => <Skeleton active key={item} />)
                ) : (
                  <>
                    {" "}
                    <LineChart borderColor="#0E9CFF" />{" "}
                  </>
                )}
              </Card>
            </Col>

            <Col xs={24} xl={12} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                <div className="graph-title">
                  <Title level={5}>
                    {lang("Sales/Revenue/Profit")} <p>{lang("Last 7 Days")}</p>
                  </Title>
                  <Row style={{ justifyContent: "end" }}>
                    <Radio.Group defaultValue="month" buttonStyle="solid">
                      <Radio.Button onClick={() => null} value="week">
                        {lang("Week")}
                      </Radio.Button>
                      <Radio.Button onClick={() => null} value="month">
                        {lang("Month")}
                      </Radio.Button>
                      <Radio.Button onClick={() => null} value="year">
                        {lang("Year")}
                      </Radio.Button>
                    </Radio.Group>
                  </Row>
                </div>
                <Tabs
                  className="custom_tabs main_tabs"
                  onTabClick={() => null}
                  tabBarStyle={{ color: "green" }}
                >
                  <TabPane
                    tab={
                      <div className="tab_title">
                        <span>24K</span>
                        <span>{lang("Restaurant")}</span>
                      </div>
                    }
                    key="restaurant"
                  >
                    {loading ? (
                      [1, 2, 3].map((item) => <Skeleton active key={item} />)
                    ) : (
                      <>
                        {" "}
                        <DoughnutCart />{" "}
                      </>
                    )}
                  </TabPane>
                  <TabPane
                    tab={
                      <div className="tab_title">
                        <span>3.5k</span> <span>{lang("Driver")}</span>
                      </div>
                    }
                    key="driver"
                  >
                    {loading ? (
                      [1, 2, 3].map((item) => <Skeleton active key={item} />)
                    ) : (
                      <>
                        {" "}
                        <DoughnutCart />
                      </>
                    )}
                  </TabPane>

                  <TabPane
                    tab={
                      <div className="tab_title">
                        <span>7.5k</span> <span>{lang("City")}</span>
                      </div>
                    }
                    key="city"
                  >
                    {loading ? (
                      [1, 2, 3].map((item) => <Skeleton active key={item} />)
                    ) : (
                      <>
                        {" "}
                        <DoughnutCart />{" "}
                      </>
                    )}
                  </TabPane>
                  <TabPane
                    tab={
                      <div className="tab_title">
                        <span>2.1</span> <span>{lang("Customer")}</span>
                      </div>
                    }
                    key="customer"
                  >
                    {loading ? (
                      [1, 2, 3].map((item) => <Skeleton active key={item} />)
                    ) : (
                      <>
                        {" "}
                        <DoughnutCart />{" "}
                      </>
                    )}
                  </TabPane>
                  <TabPane
                    tab={
                      <div className="tab_title">
                        <span>3.1k</span> <span>{lang("Area")}</span>
                      </div>
                    }
                    key="Area"
                  >
                    {loading ? (
                      [1, 2, 3].map((item) => <Skeleton active key={item} />)
                    ) : (
                      <>
                        {" "}
                        <DoughnutCart />{" "}
                      </>
                    )}
                  </TabPane>
                </Tabs>
              </Card>
            </Col>

            <Revenue />

            <CustomerSpending />

            <AcquiredCustomer />
            <DriversGraph />
          </Row> */}
        </div>
      )}
    </>
  );
}

const CountCard = ({ c, loading }) => {
  const [percentage, setPercentage] = useState();
  const [difference, setDifference] = useState();
  const { userProfile } = useAuthContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (!c) return null;

    console.log(c);
    const diff = c._7Day - c._14Day;

    const percentage = parseInt(
      ((diff / (c._7Day + c._14Day)) * 100).toFixed(2),
    );

    setPercentage(!!percentage ? percentage : 0);
  }, [c]);

  //if (!c) return null
  return (
    <Card
      hoverable
      bordered={false}
      className="criclebox"
      style={{ height: "100%" }}
      loading={loading}
      onClick={() => {
        userProfile?.type != "SubAdmin" && c.url && navigate(c.url);
      }}
    >
      <div className="number">
        <Row align="middle" gutter={[24, 0]}>
          <Col xs={18}>
            <span>{lang(c?.today)}</span>
            <p className="ftp_text">{lang("Last 7 days")}</p>
            <Title level={3}>{lang(c?.title)}</Title>
          </Col>
          <Col xs={6}>
            <div className="icon_box">
              <LineChartWithoutAxis
                isUp={percentage >= 0}
                points={[c?._14Day, c?._7Day]}
              />
            </div>
          </Col>
        </Row>
      </div>

      <div className="number_main">
        <div
          className="icon"
          style={{ color: percentage <= 0 ? "red" : "green" }}
        >
          <span>
            {percentage > 0 ? (
              <i class="fas fa-arrow-up"></i>
            ) : (
              <i class="fas fa-arrow-down"></i>
            )}
          </span>
          <span className="percentage">{Math.abs(percentage)}%</span>
        </div>
        <div className="days">
          <span>{lang("vs last 7 days")}</span>
        </div>
      </div>
    </Card>
  );
};

const Revenue = () => {
  const [loading, setLoading] = useState(false);

  return (
    <Col xs={24} xl={12} className="mb-24">
      <Card bordered={false} className="criclebox h-full">
        <div className="graph-title">
          <Title level={5}>{lang("Revenue and Profits")}</Title>
          <div className="custom_select">
            <Select
              placeholder={`${lang("Restaurant")}`}
              style={{
                width: 150,
              }}
              options={[
                {
                  value: "Restaurant",
                  label: "Restaurant",
                },
              ]}
            />
          </div>
          <Row style={{ justifyContent: "end" }}>
            <Radio.Group defaultValue="month" buttonStyle="solid">
              <Radio.Button onClick={() => null} value="week">
                {lang("Week")}
              </Radio.Button>
              <Radio.Button onClick={() => null} value="month">
                {lang("Month")}
              </Radio.Button>
              <Radio.Button onClick={() => null} value="year">
                {lang("Year")}
              </Radio.Button>
            </Radio.Group>
          </Row>
        </div>
        <Tabs
          className="main_tabs"
          onTabClick={() => null}
          //activeKey={'country'}
          tabBarStyle={{ color: "green" }}
        >
          <TabPane
            tab={
              <div className="tab_title">
                <span>24K</span> <span>{lang("country")}</span>
              </div>
            }
            key="country"
          >
            <div className="graph_inner_title">
              <h3>{lang("Sales by Country")}</h3>
              <h3>{lang("Sales")}</h3>
            </div>
            {loading ? (
              [1, 2, 3].map((item) => <Skeleton active key={item} />)
            ) : (
              <>
                <div className="home_progress">
                  <span className="progreess-left">
                    <h4>30K </h4>
                    <h5>Dubai</h5>
                  </span>
                  <Progress percent={25} />
                  <span className="progress-right">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L10 8L15 13"
                        stroke="#28C76F"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    25.8%
                  </span>
                </div>
                <div className="home_progress">
                  <span className="progreess-left">
                    <h4>26K </h4>
                    <h5>Abu Dhabi</h5>
                  </span>
                  <Progress percent={16} status="active" />
                  <span className="progress-right">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L10 8L15 13"
                        stroke="#28C76F"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    25.8%
                  </span>
                </div>
                <div className="home_progress">
                  <span className="progreess-left">
                    <h4>22K </h4>
                    <h5>Sharjah</h5>
                  </span>
                  <Progress percent={13} status="exception" />
                  <span className="progress-right">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L10 8L15 13"
                        stroke="#28C76F"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    25.8%
                  </span>
                </div>
                <div className="home_progress">
                  <span className="progreess-left">
                    <h4>17K </h4>
                    <h5>Ajman</h5>
                  </span>
                  <Progress percent={11} />
                  <span className="progress-right">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L10 8L15 13"
                        stroke="#28C76F"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    25.8%
                  </span>
                </div>
              </>
            )}
          </TabPane>
          <TabPane
            tab={
              <div className="tab_title">
                <span>2k</span> <span>{lang("City")}</span>
              </div>
            }
            key="city"
          >
            <div className="graph_inner_title">
              <h3>{lang("Sales by Cities")}</h3>
              <h3>{lang("Sales")}</h3>
            </div>
            {loading ? (
              [1, 2, 3].map((item) => <Skeleton active key={item} />)
            ) : (
              <>
                <div className="home_progress">
                  <span className="progreess-left">
                    <h4>30K </h4>
                    <h5>Dubai</h5>
                  </span>
                  <Progress percent={95} />
                  <span className="progress-right">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L10 8L15 13"
                        stroke="#28C76F"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    95.8%
                  </span>
                </div>
                <div className="home_progress">
                  <span className="progreess-left">
                    <h4>26K </h4>
                    <h5>Abu Dhabi</h5>
                  </span>
                  <Progress percent={32} status="active" />
                  <span className="progress-right">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L10 8L15 13"
                        stroke="#28C76F"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    32.8%
                  </span>
                </div>
                <div className="home_progress">
                  <span className="progreess-left">
                    <h4>22K </h4>
                    <h5>Sharjah</h5>
                  </span>
                  <Progress percent={13} status="exception" />
                  <span className="progress-right">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L10 8L15 13"
                        stroke="#28C76F"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    25.8%
                  </span>
                </div>
                <div className="home_progress">
                  <span className="progreess-left">
                    <h4>17K </h4>
                    <h5>Ajman</h5>
                  </span>
                  <Progress percent={49} />
                  <span className="progress-right">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L10 8L15 13"
                        stroke="#28C76F"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    49.8%
                  </span>
                </div>
              </>
            )}
          </TabPane>

          <TabPane
            tab={
              <div className="tab_title">
                <span>3.1k</span> <span>{lang("Area")}</span>
              </div>
            }
            key="area"
          >
            <div className="graph_inner_title">
              <h3>{lang("Sales by Area")}</h3>
              <h3>{lang("Sales")}</h3>
            </div>
            {loading ? (
              [1, 2, 3].map((item) => <Skeleton active key={item} />)
            ) : (
              <>
                <div className="home_progress">
                  <span className="progreess-left">
                    <h4>30K </h4>
                    <h5>Dubai</h5>
                  </span>
                  <Progress percent={35} />
                  <span className="progress-right">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L10 8L15 13"
                        stroke="#28C76F"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    35.8%
                  </span>
                </div>
                <div className="home_progress">
                  <span className="progreess-left">
                    <h4>26K </h4>
                    <h5>Abu Dhabi</h5>
                  </span>
                  <Progress percent={58} status="active" />
                  <span className="progress-right">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L10 8L15 13"
                        stroke="#28C76F"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    58.8%
                  </span>
                </div>
                <div className="home_progress">
                  <span className="progreess-left">
                    <h4>22K </h4>
                    <h5>Sharjah</h5>
                  </span>
                  <Progress percent={13} status="exception" />
                  <span className="progress-right">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L10 8L15 13"
                        stroke="#28C76F"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    25.8%
                  </span>
                </div>
                <div className="home_progress">
                  <span className="progreess-left">
                    <h4>17K </h4>
                    <h5>Ajman</h5>
                  </span>
                  <Progress percent={17} />
                  <span className="progress-right">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L10 8L15 13"
                        stroke="#28C76F"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    25.8%
                  </span>
                </div>
              </>
            )}
          </TabPane>
        </Tabs>
      </Card>
    </Col>
  );
};

const CustomerSpending = () => {
  const [loading, setLoading] = useState(false);

  return (
    <Col xs={24} xl={12} className="mb-24">
      <Card bordered={false} className="criclebox h-full">
        <div className="graph-title">
          <Title level={5}>{lang("Customers Spending")}</Title>
          <Row style={{ justifyContent: "end" }}>
            <Radio.Group defaultValue="month" buttonStyle="solid">
              <Radio.Button onClick={() => null} value="week">
                {lang("Week")}
              </Radio.Button>
              <Radio.Button onClick={() => null} value="month">
                {lang("Month")}
              </Radio.Button>
              <Radio.Button onClick={() => null} value="year">
                {lang("Year")}
              </Radio.Button>
            </Radio.Group>
          </Row>
        </div>
        <Tabs
          className="main_tabs"
          onTabClick={() => null}
          //activeKey={'country'}
          tabBarStyle={{ color: "green" }}
        >
          <TabPane
            tab={
              <div className="tab_title">
                <span>2K</span> <span>{lang("Country")}</span>
              </div>
            }
            key="country"
          >
            <div className="graph_inner_title">
              <h3>{lang("Country")}</h3>
              <h3>{lang("Sales")}</h3>
            </div>
            {loading ? (
              [1, 2, 3].map((item) => <Skeleton active key={item} />)
            ) : (
              <>
                <div className="home_progress">
                  <span className="progreess-left">
                    <h4>30K </h4>
                    <h5>Dubai</h5>
                  </span>
                  <Progress percent={25} />
                  <span className="progress-right">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L10 8L15 13"
                        stroke="#28C76F"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    25.8%
                  </span>
                </div>
                <div className="home_progress">
                  <span className="progreess-left">
                    <h4>26K </h4>
                    <h5>Abu Dhabi</h5>
                  </span>
                  <Progress percent={16} status="active" />
                  <span className="progress-right">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L10 8L15 13"
                        stroke="#28C76F"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    25.8%
                  </span>
                </div>
                <div className="home_progress">
                  <span className="progreess-left">
                    <h4>22K </h4>
                    <h5>Sharjah</h5>
                  </span>
                  <Progress percent={13} status="exception" />
                  <span className="progress-right">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L10 8L15 13"
                        stroke="#28C76F"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    25.8%
                  </span>
                </div>
                <div className="home_progress">
                  <span className="progreess-left">
                    <h4>17K </h4>
                    <h5>Ajman</h5>
                  </span>
                  <Progress percent={11} />
                  <span className="progress-right">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L10 8L15 13"
                        stroke="#28C76F"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    25.8%
                  </span>
                </div>
              </>
            )}
          </TabPane>
          <TabPane
            tab={
              <div className="tab_title">
                <span>20k</span> <span>{lang("City")}</span>
              </div>
            }
            key="city"
          >
            <div className="graph_inner_title">
              <h3>{lang("Cities")}</h3>
              <h3>{lang("Sales")}</h3>
            </div>
            {loading ? (
              [1, 2, 3].map((item) => <Skeleton active key={item} />)
            ) : (
              <>
                <div className="home_progress">
                  <span className="progreess-left">
                    <h4>30K </h4>
                    <h5>Dubai</h5>
                  </span>
                  <Progress percent={95} />
                  <span className="progress-right">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L10 8L15 13"
                        stroke="#28C76F"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    95.8%
                  </span>
                </div>
                <div className="home_progress">
                  <span className="progreess-left">
                    <h4>26K </h4>
                    <h5>Abu Dhabi</h5>
                  </span>
                  <Progress percent={32} status="active" />
                  <span className="progress-right">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L10 8L15 13"
                        stroke="#28C76F"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    32.8%
                  </span>
                </div>
                <div className="home_progress">
                  <span className="progreess-left">
                    <h4>22K </h4>
                    <h5>Sharjah</h5>
                  </span>
                  <Progress percent={13} status="exception" />
                  <span className="progress-right">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L10 8L15 13"
                        stroke="#28C76F"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    25.8%
                  </span>
                </div>
                <div className="home_progress">
                  <span className="progreess-left">
                    <h4>17K </h4>
                    <h5>Ajman</h5>
                  </span>
                  <Progress percent={49} />
                  <span className="progress-right">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13L10 8L15 13"
                        stroke="#28C76F"
                        stroke-width="1.75"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    49.8%
                  </span>
                </div>
              </>
            )}
          </TabPane>

          {/* <TabPane tab={<div className="tab_title"><span>3.1k</span> <span>Area</span></div>} key="area">
      <div className="graph_inner_title">
        <h3>Sales by Area</h3>
        <h3>Sales</h3>
      </div>
        {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) : <>
          <div className="home_progress"><span className="progreess-left"><h4>30K </h4><h5>Dubai</h5></span>
          <Progress percent={35} />
            <span className="progress-right">
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 13L10 8L15 13" stroke="#28C76F" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>35.8%
            </span>
          </div>
          <div className="home_progress"><span className="progreess-left"><h4>26K </h4><h5>Abu Dhabi</h5></span><Progress percent={58} status="active" /><span className="progress-right"><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 13L10 8L15 13" stroke="#28C76F" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          58.8%</span></div>
          <div className="home_progress"><span className="progreess-left"><h4>22K </h4><h5>Sharjah</h5></span><Progress percent={13} status="exception" /><span className="progress-right"><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 13L10 8L15 13" stroke="#28C76F" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          25.8%</span></div>
          <div className="home_progress"><span className="progreess-left"><h4>17K </h4><h5>Ajman</h5></span><Progress percent={17} /><span className="progress-right"><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 13L10 8L15 13" stroke="#28C76F" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          25.8%</span></div>
        </>}
      </TabPane> */}
        </Tabs>
      </Card>
    </Col>
  );
};

const DiscountPerformance = () => {
  const [loading, setLoading] = useState(false);

  return (
    <Col xs={24} xl={12} className="mb-24">
      <Card bordered={false} className="criclebox h-full">
        <div className="graph-title">
          <Title level={5}>Discount Performance</Title>
          <Row style={{ justifyContent: "end" }}>
            <Radio.Group defaultValue="month" buttonStyle="solid">
              <Radio.Button onClick={() => null} value="week">
                Week
              </Radio.Button>
              <Radio.Button onClick={() => null} value="month">
                Month
              </Radio.Button>
              <Radio.Button onClick={() => null} value="year">
                Year
              </Radio.Button>
            </Radio.Group>
          </Row>
        </div>
        {loading ? (
          [1, 2, 3].map((item) => <Skeleton active key={item} />)
        ) : (
          <>
            {" "}
            <AreaCart />{" "}
          </>
        )}
      </Card>
    </Col>
  );
};

export default Home;
